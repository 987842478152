import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the home page after 1 second
    const timer = setTimeout(() => {
      navigate("/");
    }, 1000);

    // Clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className='flex items-center justify-center h-screen'>
      <div className='text-center'>
        <h1 className='text-4xl font-bold'>404 - Page Not Found</h1>
        <p className='mt-4'>The page you are looking for does not exist.</p>
        <p className='mt-4'>
          You will be redirected to the home page shortly...
        </p>
      </div>
    </div>
  );
};

export default NotFound;
